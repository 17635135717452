import { MaxLengthValidator, PatternValidator } from '@angular/forms';

export const Validations = {
  LoginPage: {
    email_mobile_validation: 'Email / Mobile Number is required',
    password_required_validation: 'Password is required',
    otp_required_validation: 'Otp is required',
    otp_success_msz: 'OTP has been sent to your email and Phone number.',
  },
  ForgotPwdPage: {
    email_mobile_validation: 'Email / Mobile Number is required',
    otp_success_msz: 'OTP has been sent to your email and Phone number.',
  },
  ResetPwdPage: {
    otp_required_validation: 'Otp is required',
    newpwd_required_validation: 'Password is required',
    confirmPwd_required_validation: 'Confirm Password is required',
    matchPwd_validation: 'Confirm Password should match with Password',
  },
  billCharge: {
    name: 'Name is required',
    amount: 'Amount is required',
    gst: 'GST is required',
    utilityType: 'Utility is required',
    patten: 'Only numbers(allow 1 - 28 numbers)',
    total: 'Total is required',
    // totalPattren: 'Only numbers(allow 1 - 28 numbers)',
    OnlyAlphanumeric_patten: 'Only Alpha numeric are allowed',
    Onlynumberwithdecimals: 'Only Number with  Decimals allowed',
    dateRange: 'From and to Date  is required',
    slot: 'Slot is required',
    file: 'File is required',

    PenaltyApplicable_dateOthers: ' Select Penalty Applicable date Others ',
    PenaltyApplicable_dateWater: 'Select Penalty Applicable date Water ',
    PenaltyApplicable_dateGas: 'Select Penalty Applicable date Gas ',
    PenaltyApplicable_dateElectricity:
      'Select  Penalty Applicable date Electricity ',
    PenalityAmountForElectricity: 'Penality Amount for Electricity is required',
    PenalityAmountForGas: 'Penality Amount for Gas is required',
    PenalityAmountForWater: 'Penality Amount for Water is required',
    PenalityAmountForOthers: 'Penality Amount for Others is required',
    PenalityAmount: 'Penality Amount is required',
    Penalitydate: 'Penality date is required',
  },

  bill: {
    totalAmount: ' Total Amount is required',
    billStatus: ' Bill Status is required',
    consumer: ' Consumer is required',
    utility: ' Utility is required',
    residentialUnit: 'Residential Unit is required',
    mterNumber: ' Meter Number is required',
    selectBillForMonth: ' Select Bill for Month is required',
    generalCharge: ' General Charges is required',
    billDays: ' Bill Days is required',
    SelectDatesForBillGaneration:
      ' Select Dates for Bill generators is required',
  },

  residentialUnit: {
    flat_House: 'flat/House is required',
    floor: 'floor is required',
    block: 'block is required',
    tower: 'tower is required',
    patten: 'Only alphanumeric & special chars (#-,)',

    OnlyAlphanumeric_patten: 'Only Alpha numeric are allowed',
    OnlyNumberDecimal_patten: 'Only Number with 2 decimals allowed',

    utilityType: 'utilityType is required',
    utilitymeters: 'utility Meters is required',
    occupantDropdown: 'occupant name  is required',
    move_in_date: 'move in date is required',
    InstalledLocation: 'Installed Location  is required',
    InitialReading: 'Initial reading is required',
    VacateDate: 'Vacate Date is required',

    MeterinitialReadingDate: 'Meter Initial Reading date is required',
    Meter_Installation_Date: 'Meter installation date is required',
    Meter_Initial_Reading_DG_KWH: 'Meter Initial Reading DG KWH is required',
    Meter_Initial_Reading_EB_KWH: 'Meter Initial Reading EB KWH is required',
  },

  consumersPage: {
    Occupied_residential_unit: 'Occupied residential unit  is required',
    Registered_date: 'Registered date  is required',
    enable_wallet: 'enable_wallet id is required',
    feedback: 'Feedback is required',
    min_feedback: 'feedback  Should Conatines min 2 characters',
    co_name: {
      Enter_co_name_required_Validations: 'Enter_co_name is required',
      min_required_Validations:
        'Enter_co_name Should Conatines min 1 and max 25 characters',
      allow_spaces: 'Only Alpbets & spaces',
    },
    mobile_validation: {
      mobile_required_validations: 'Mobile number primary is required',
      mobile_mix_required_Validations:
        'Valid Mobile number primary Format max 10 characters',
      numbers_only: 'Only numbers',
    },
    surName: {
      surName_required_Validations: 'surName is required',
      min_required_Validations:
        'surName Should Conatines min 2 and max 25 characters',
      allow_spaces: 'Only Alpbets & spaces',
    },
  },

  ProfilePage: {
    firstName: {
      firstName_required_Validations: 'First Name is required',
      min_required_Validations:
        'First Name  Should Conatines min 2 and max 25 characters',
      allow_spaces: 'Only Alpbets & spaces',
    },
    lastName: {
      lastName_required_Validations: 'Last Name is required',
      min_required_Validations:
        'First Name  Should Conatines min 2 and max 25 characters',
      allow_spaces: 'Only Alpbets & spaces',
    },
    secondaryPhoneNumber: {
      lastName_required_Validations: 'secondaryPhoneNumber is required',
      min_required_Validations:
        'secondaryPhoneNumber  Should Conatines min 10 and max 10 characters',
      allow_spaces: 'Only numbers',
    },
    emmail_validation: {
      email_required_validations: 'Email Address is required',
      email_pattern: 'Email Must be a valid email address',
    },
    mobile_validation: {
      mobile_required_validations: 'Mobile Number is required',
      mobile_mix_required_Validations:
        'Valid Mobile Number Format max 10 characters',
      numbers_only: 'Only numbers',
    },
    password_validation: {
      password_required_validations: 'Password is required',
      password_Invalid_pattern: 'Password Invalid!',
    },
  },
  ChangePwdPage: {
    oldPassword: {
      required: 'Old Password is required',
      minLengthValidator: 'Old Password should contain min of 8 characters',
      MaxLengthValidator: 'Old Password should contain max of 16 characters ',
    },
    newPassword: {
      required: 'New Password is required',
      minLengthValidator: 'New Password should contain min of 8 characters',
      MaxLengthValidator: 'New Password should contain max of 16 characters ',
      patternValidation: 'Password Must include one uppercase letter, one lowercase letter, one special character,one digit and be at least 6 characters long.',
    },
    confirmPassword: {
      required: 'Confirm Password is required',
      minLengthValidator: 'Confirm Password should contain min of 8 characters',
      MaxLengthValidator:
        'Confirm Password should contain max of 16 characters ',
      patternValidation: 'Password Must include one uppercase letter, one lowercase letter, one special character,one digit and be at least 6 characters long.',
    },

    match_required_validations:
      'Confirm Password should match with New Password',
    oldPwdandNewPwd_validations:
      'Old password and new password cannot be the same.',
  },
  match_required_validations:
  'Confirm Password should match with New Password',
oldPwdandNewPwd_validations:
  'Old password and new password cannot be the same.',
  // gatewayPage: {
  //   gateWayAddress_required_Validations: 'serial Number is required',
  //   min_required_Validations:
  //     'First Name  Should Conatines min 2 and max 40 characters',
  //   allow_spaces: 'Only Alpha numeric are allowed',
  // },
  ServiceError: {
    // Error: 'SomeThing Went wrong please contact administrator',
    Error:''
  },

  Project_ValidationMessages: {
    project_Name: {
      required: 'Project name is required',
      minLengthValidator: 'Project name should contain min of 2 characters',
      MaxLengthValidator: 'Project name should contain max of 20 characters ',
      patternValidation: 'Only alpha numeric & special chars(-_&) allowed',
    },
    project_Code: {
      patternValidation: 'Only alpha numeric allowed',
    },

    projectType: {
      required: 'Project type is required',
    },
    Status: {
      required: 'Project Status is required',
    },
    region: {
      required: 'Region is required',
    },
    address: {
      required: 'Project address is required',
    },
    area: {
      required: 'Area / Locality is required',
      patternValidation: 'Only alphabets & spaces allowed',
    },
    city: {
      required: 'City is required',
    },
    state: {
      required: 'State is required',
    },
    pincode: {
      required: 'Pincode is required',
      minLengthValidator: 'Pincode name should contain 6 digits',
      MaxLengthValidator: 'Pincode name should contain 6 digits ',
      patternValidation:
        'Only Numeric, Enter a valid pincode (If not entered 6 characters)',
    },
    society_name: {
      required: 'Society head details - Name is required',
      minLengthValidator: 'Society name should contain min of 2 characters',
      MaxLengthValidator: 'Society name should contain max of 20 characters ',
      patternValidation: 'Only alpha numeric & spaces allowed',
    },
    merchantId: {
      required: 'Merchant ID is required',
      patternValidation: 'Only alpha numeric allowed',
    },
    accountName: {
      required: 'Account name is required',
      patternValidation: 'Only alpha numeric & spaces allowed',
    },
    accountNumber: {
      required: 'Account number is required',
      patternValidation: 'Only number allowed',
    },
    bankName: {
      required: 'Bank name is required',
      patternValidation: 'Only alphabets & spaces allowed',
    },
    branchName: {
      required: 'Branch name is required',
      patternValidation: 'Only alphabets & spaces allowed',
    },
    ifscCode: {
      required: 'IFSC Code is required',
      patternValidation: 'Only alpha numeric allowed',
    },
    billingType: {
      required: 'Billing type is required',
    },
    BillGenerationTypeId: {
      required: 'Bill Generation Type is required',
    },
    minRechargeAmount: {
      required: 'Minimum recharge amount is required',
      patternValidation:
        'Minimum Recharge Amount must be a number with up to 2 decimal places',
    },
    minWalletBalance: {
      required: 'Minimum wallet balance is required',
      patternValidation:
        'Minimum Recharge Amount must be a number with up to 2 decimal places',
    },
    valueCutofflimit: {
      required: 'Value cut limit is required',
      patternValidation:
        'Minimum Recharge Amount must be a number with up to 2 decimal places',
    },
    maxRechargeAmount: {
      required: 'Maximum recharge amount is required',
      patternValidation:
        'Minimum Recharge Amount must be a number with up to 2 decimal places',
    },
    billDate: {
      required: 'Project billing date is required',
    },
    db_serverName: {
      patternValidation: 'Only alpha numeric allowed',
    },
    db_userName: {
      patternValidation: 'Only alpha numeric allowed',
    },
    gasProvider: {
      required: 'Gas provider is required',
    },
    electricityProvider: {
      required: 'Electricity provider is required',
    },
    waterProvider: {
      required: 'Water provider is required',
    },
    required_all_mandatoryFields: 'Please fill all Mandatory fields',
  },

  MasterCreatePage: {
    masterName: {
      required: 'Master name is required',
      minLengthValidator: 'Master name should contain min of 2 characters',
      MaxLengthValidator: 'Master name should contain max of 20 characters ',
      patternValidation: 'Only Alpha numeric & spaces are allowed',
    },
    masterType: {
      required: 'MasterType is required',
    },
  },

  SupplierCreatePage: {
    SupplierName: {
      required: 'Supplier name is required',
      minLengthValidator: 'Supplier name should contain min of 2 characters',
      MaxLengthValidator: 'Supplier name should contain max of 20 characters ',
      patternValidation: 'Only Alpha numeric & spaces are allowed',
    },
    masterId: {
      required: 'MasterType is required',
    },
  },

  MonitoringCreatePage: {
    MeterNumber: {
      required: 'Meter Number is required',
      minLengthValidator: 'Meter Number should contain min of 2 characters',
      MaxLengthValidator: 'Meter Number should contain max of 20 characters ',
      patternValidation: 'Only Alpha numeric & spaces are allowed',
    },
    utilityId: {
      required: 'Utility is required',
    },
  },

  gas: {
    gasValue: {
      required: 'Gas value is required',
      minLengthValidator: 'Gas name should contain min of 2 characters',
      MaxLengthValidator: 'Gas name should contain max of 20 characters ',
      patternValidation: 'only numbers allowed',
    },
    kgLpg: {
      required: '1 kg / LPG is required',
      patternValidation: 'Only numbers with / without decimals allowed',
    },
    unitPrice: {
      required: 'Unit price is required',
      patternValidation: 'Only numbers with / without decimals allowed',
    },
  },
  water: {
    slabName: {
      required: 'Slab name is required',
      patternValidation: 'Only numbers with / without decimals allowed',
    },
    min: {
      required: 'Min is required',
      patternValidation: 'Only numbers with / without decimals allowed',
    },
    max: {
      required: 'Max is required',
      patternValidation: 'Only numbers with / without decimals allowed',
    },
    slabPerUnit: {
      required: 'Unit price is required',
      patternValidation: 'Only numbers with decimals allowed',
    },
  },
  electricity: {
    slabName: {
      required: 'Slab name is required',
    },
    minKwh: {
      required: 'Min kWh is required',
      patternValidation: 'Only numbers with / without decimals allowed',
    },
    maxKwh: {
      required: 'Max kWh is required',
      patternValidation: 'Only numbers with / without decimals allowed',
    },
    slabPerUnitPrice: {
      required: 'Unit price is required',
      patternValidation: 'Only numbers with decimals allowed',
    },
  },
  umd: {
    utility: {
      required: 'Utility is required',
    },
    macAddress: {
      required: 'Mac address is required',
    },
    meterStatus: {
      required: 'Meter status is required',
    },
  },

  UmdCreatePage: {
    utilityMasterId: {
      required: 'Utility Master is required',
    },
    macAddress: {
      required: 'Mac Address is required',
    },
    meterStatus: {
      required: 'Meter Status is required',
    },
  },

  payloadConfig: {
    payloadName: {
      required: 'Payload name is required',
      minLengthValidator: 'Payload name should contain min of 2 characters',
      MaxLengthValidator: 'Payload name should contain max of 20 characters ',
      patternValidation: 'Only Alpha numeric & spaces are allowed',
    },
    parameterName: {
      required: 'Parameter name is required',
      minLengthValidator: 'Parameter name should contain min of 2 characters',
      MaxLengthValidator: 'Parameter name should contain max of 20 characters ',
      patternValidation: 'Only Alpha numeric & spaces are allowed',
    },
    positionFrom: {
      required: 'positionFrom  is required',
      minLengthValidator: 'Master name should contain min of 2 characters',
      MaxLengthValidator: 'Master name should contain max of 20 characters ',
      patternValidation: 'Only Numbers allowed',
    },
    positionTo: {
      required: 'PositionTo is required',
      minLengthValidator: 'Master name should contain min of 2 characters',
      MaxLengthValidator: 'Master name should contain max of 20 characters ',
      patternValidation: 'Only Numbers allowed',
    },
    dataType: {
      required: 'DataType  is required',
      minLengthValidator: 'DataType  should contain min of 2 characters',
      MaxLengthValidator: 'DataType  should contain max of 20 characters ',
      patternValidation: 'Only Alpha numeric & spaces are allowed',
    },
  },

  keymappingForm: {
    keyDisplayName: {
      required: 'Key display name is required',
    },
    jsonkeyName: {
      required: 'Json key name is required',
    },
  },
  devices: {
    deviceSRNo: {
      required: 'Device SR No is required ',      
      pattern: 'DeviceSRNo Only Accept Digits and Uppercase letters'
    },
    macAddress: {
      required: 'MAC Address is required.',
       pattern: 'Mac Address  Only Accept Digits and Uppercase letters'
    },
    masterId: {
      required: 'Utility is required.',
    },
    supplierId: {
      required: 'Supplier  is required.',
    },
    projectId: {
      required: 'Project  is required.',
    },
    yearOfMake: {
      required: 'Year of Make is required.',
    },
    responseConfigurationId: {
      required: 'Response Configuration  is required.',
    },
    isMonitoringDevice: {
      required: 'MonitoringDevice is required.',
    },
    gatewayInformationId:{
      required: 'GateWay is required.',
    },
    deviceId:{
      required: 'DeviceId is required.',
      pattern: ' Device ID must contain only numeric values.',
    },
    meterReading: {
      required: 'meter Reading is required.',
    },
    meterReadingDG: {
      required: 'Meter ReadingDG is required.',
    },
    meterReadingEB: {
      required: 'Meter ReadingEB is required.',
    },
    meterReadingTS:{
      required: 'Meter ReadingTS is required.',
    }
  },

  ResponseConfigurationForm: {
    responseName: {
      required: 'Response Name is required',
    },
    projectId: {
      required: 'Project ID is required',
    },
    supplierId: {
      required: 'Supplier is required',
    },
    payload: {
      required: 'Payload is required',
    },
    gatewayAddress: {
      required: 'Gateway Address is required',
    },
    deviceAddress: {
      required: 'Device Address is required',
    },
    methodUrlMappingId: {
      required: 'Method URL Mapping ID is required',
    },
    methodUrl: {
      required: 'Method URL is required',
    },
  },

  gatewayPage: {
    gateWayAddress: {
      required: 'GateWay Address is required',
      min_required_Validations:
        'GateWay Address should contain max 200 characters',
      allow_spaces: 'Only Alpbets & 1-spaces allowed',
    },
    project: {
      required: 'Project is required',
    },
  },
  ThirdPartyPage: {
    endpointName: {
      required: 'EndPoint is required',
    },
    url: {
      required: 'Url is required',
    },
    project: {
      required: 'Project is required',
    },
  }

};
