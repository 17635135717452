export enum SweetAlertIcon {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
  QUESTION = 'question',
}

export enum Roles {
  ADMIN = 'Admin',
  SUPERADMIN = 'SuperAdmin',
  CONSUMER = 'Consumer',
  AGENT = 'Agent',
}

export const superadmin_graphColors = [
  '#F9AF58', // Orange shade
  '#6ED972', // Green shade
  '#3498db', // Example: Blue shade
  '#e74c3c', // Example: Red shade
];
